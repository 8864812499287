import React, { FC, lazy, Suspense } from "react";
import { useLocation } from "react-router-dom";
import { ChakraProvider, extendTheme, Flex, Spinner } from "@chakra-ui/react";

const AuthContextProvider = lazy(
  () => import("./components/contexts/AuthContext")
);
const AdminRoute = lazy(() => import("./components/routes/AdminRoute"));
const StoreContextProvider = lazy(
  () => import("./components/contexts/StoreContext")
);
const CustomerFacingScreen = lazy(
  () => import("./components/customer-facing-screen/CustomerFacingScreen")
);

const PublicRoute = lazy(() => import("./components/routes/PublicRoute"));
const OrderRoute = lazy(() => import("./components/routes/OrderRoute"));

const App: FC = () => {
  const theme = extendTheme({
    shadows: { outline: "none" },
    fonts: {
      heading: "Monsterrat, sans-serif",
      body: "Monsterrat, sans-serif",
    },
    colors: {
      submit: {
        100: "#45f776",
        200: "#3fe06b",
        300: "#3ccf64",
        400: "#35b859",
        500: "#32a852",
        600: "#2b9447",
        700: "#23783a",
        800: "#1c612f",
        900: "#144521",
      },
      edit: {
        100: "#4058de",
        200: "#3b51cc",
        300: "#364aba",
        400: "#3244a8",
        500: "#2c3b91",
        600: "#243178",
        700: "#1c2661",
        800: "#151d4a",
        900: "#0e1330",
      },
      remove: {
        100: "#fa4646",
        200: "#ed4545",
        300: "#de4040",
        400: "#c73838",
        500: "#b53333",
        600: "#a12d2d",
        700: "#8f2828",
        800: "#782222",
        900: "#611a1a",
      },
    },
  });

  const RenderRoute = () => {
    const location = useLocation();
    if (location.pathname.startsWith("/order")) {
      return (
        <Suspense
          fallback={
            <Flex height="100vh" width="full" align="center" justify="center">
              <Spinner
                size="xl"
                speed="0.9s"
                thickness="4px"
                color="orange.300"
              />
            </Flex>
          }
        >
          <OrderRoute />
        </Suspense>
      );
    } else if (
      location.pathname.includes("/admin") ||
      location.pathname === "/profile" ||
      location.pathname === "/email-verification" ||
      location.pathname === "/setting"
    ) {
      return (
        <Suspense
          fallback={
            <Flex height="100vh" width="full" align="center" justify="center">
              <Spinner
                size="xl"
                speed="0.9s"
                thickness="4px"
                color="orange.300"
              />
            </Flex>
          }
        >
          <StoreContextProvider>
            <AdminRoute />
          </StoreContextProvider>
        </Suspense>
      );
    } else if (location.pathname === "/customer") {
      return (
        <Suspense
          fallback={
            <Flex height="100vh" width="full" align="center" justify="center">
              <Spinner
                size="xl"
                speed="0.9s"
                thickness="4px"
                color="orange.300"
              />
            </Flex>
          }
        >
          <CustomerFacingScreen />
        </Suspense>
      );
    } else {
      return (
        <Suspense
          fallback={
            <Flex height="100vh" width="full" align="center" justify="center">
              <Spinner
                size="xl"
                speed="0.9s"
                thickness="4px"
                color="orange.300"
              />
            </Flex>
          }
        >
          <PublicRoute />
        </Suspense>
      );
    }
  };
  const AppRoute = () => {
    return (
      <>
        <RenderRoute />
        {/* <CookieConsent
          debug={true}
          style={{ background: "#212529" }}
          buttonStyle={{
            color: "#212529",
            background: "#fed435",
            fontSize: "13px",
          }}
          expires={365}
          buttonText="Allow Cookies"
        >
          We use cookies to improve your experience and functionality for the
          application, please read and agree with our{" "}
          <a href="#">Cookies Policy</a>
        </CookieConsent> */}
      </>
    );
  };

  return (
    <ChakraProvider theme={theme}>
      <Suspense
        fallback={
          <Flex height="100vh" width="full" align="center" justify="center">
            <Spinner
              size="xl"
              speed="0.9s"
              thickness="4px"
              color="orange.300"
            />
          </Flex>
        }
      >
        <AuthContextProvider>
          <AppRoute />
        </AuthContextProvider>
      </Suspense>
    </ChakraProvider>
  );
};

export default App;


// https://colorhunt.co/palette/f1f6f914274e3948679ba4b4