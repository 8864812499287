import moment from "moment";
import React, { useState, useContext, useEffect } from "react";
import "moment/locale/zh-cn";
import "moment/locale/zh-hk";

const LanguageContext = React.createContext();
export const useLanguageContext = () => useContext(LanguageContext);

export default function LanguageContextPrivider({ children }) {
  const [language, changeLanguage] = useState("en");
  const momentLocale =
    language === "en" ? "en" : language === "cn" ? "zh-cn" : "zh-hk";

  useEffect(() => {
    changeLanguage(localStorage.getItem("language") || "en");
  }, []);

  moment.locale(momentLocale);

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
}
